import React from 'react';
import {Image} from '@sitecore-jss/sitecore-jss-react';
import ScrollAnimation from 'react-animate-on-scroll';

const BannerImage = ({fields}) => {
	const img = <Image field={fields.image} data-amid={fields.image.id} />;
	return (
		<div className="image top-part">
			{fields.hasFade.value ? (
				<ScrollAnimation
					animateIn="slightZoomOut"
					animateOnce
					initiallyVisible
					delay={100}
					duration={0.25}
				>
					{img}
				</ScrollAnimation>
			) : (
				img
			)}
		</div>
	);
};

export default BannerImage;
